import * as client_hooks from '../../../src/entry/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92')
];

export const server_loads = [0];

export const dictionary = {
		"/(authenticated)/(root)/(organization)": [13,[2,3]],
		"/(not-authenticated)/(deprecated)/access/[project]": [63,[12]],
		"/(not-authenticated)/(deprecated)/access/[project]/role/[role]": [64,[12]],
		"/(not-authenticated)/(deprecated)/audit": [65,[12]],
		"/(not-authenticated)/(deprecated)/audit/breeam/[project]": [66,[12]],
		"/(not-authenticated)/(deprecated)/audit/breeam/[project]/[phase]": [67,[12]],
		"/(not-authenticated)/(deprecated)/audit/breeam/[project]/[phase]/criterion-[criterion]/[iteration]": [68,[12]],
		"/(not-authenticated)/(deprecated)/audit/breeam/[project]/[phase]/subject-[subject]/[iteration]": [69,[12]],
		"/(not-authenticated)/callback": [90,[12]],
		"/(authenticated)/create-project": [25,[2]],
		"/(authenticated)/download": [26,[2]],
		"/(authenticated)/edit": [27,[2,6]],
		"/(authenticated)/edit/create-manual": [28,[2,6]],
		"/(authenticated)/edit/manual-view/[manual]": [29,[2,6,7]],
		"/(authenticated)/edit/manual-view/[manual]/config": [30,[2,6,7]],
		"/(authenticated)/edit/manual-view/[manual]/faq": [31,[2,6,7]],
		"/(authenticated)/edit/manual-view/[manual]/manual-structure": [32,[2,6,7]],
		"/(authenticated)/edit/manual-view/[manual]/object-view": [33,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/criterion/[id]": [34,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/criterion/[id]/cms": [35,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/field/[id]": [36,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/field/[id]/cms": [37,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/subject/[id]": [38,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/subject/[id]/cms": [39,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/template/[id]": [40,[2,6,7,8]],
		"/(authenticated)/edit/manual-view/[manual]/object-view/template/[id]/cms": [41,[2,6,7,8]],
		"/(not-authenticated)/(deprecated)/forgot-password": [70,[12]],
		"/(not-authenticated)/invitation/[key]": [91,[12]],
		"/(not-authenticated)/(deprecated)/log-in": [71,[12]],
		"/(authenticated)/(root)/(organization)/organization/create": [19,[2,3]],
		"/(authenticated)/(root)/(organization)/organization/move-existing-project": [20,[2,3]],
		"/(authenticated)/(root)/(organization)/organization/[organization]/portfolios": [17,[2,3,4]],
		"/(authenticated)/(root)/(settings)/organization/[organization]/settings/organization/general": [21,[2,5]],
		"/(authenticated)/(root)/(settings)/organization/[organization]/settings/organization/members": [22,[2,5]],
		"/(authenticated)/(root)/(settings)/organization/[organization]/settings/portfolio/[portfolio]/general": [23,[2,5]],
		"/(authenticated)/(root)/(settings)/organization/[organization]/settings/portfolio/[portfolio]/members": [24,[2,5]],
		"/(authenticated)/(root)/(organization)/organization/[organization]/statistics": [18,[2,3,4]],
		"/(authenticated)/(root)/(organization)/organization/[organization]/[portfolio]/assets": [14,[2,3,4]],
		"/(authenticated)/(root)/(organization)/organization/[organization]/[portfolio]/assets/[asset]": [15,[2,3,4]],
		"/(authenticated)/(root)/(organization)/organization/[organization]/[portfolio]/certifications": [16,[2,3,4]],
		"/(not-authenticated)/(deprecated)/pre-analysis/breeam/[project]": [72,[12]],
		"/(not-authenticated)/(deprecated)/project/breeam/[project]": [86,[12]],
		"/(not-authenticated)/(deprecated)/project/breeam/[project]/[phase]": [87,[12]],
		"/(not-authenticated)/(deprecated)/project/breeam/[project]/[phase]/[category]": [88,[12]],
		"/(not-authenticated)/(deprecated)/project/breeam/[project]/[phase]/[category]/[subject]": [89,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]": [74,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/audit": [75,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/editor/pdf/[id]": [76,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/overview": [77,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/overview/edit": [78,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings": [79,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings/general": [80,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings/notifications": [81,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings/profile": [82,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings/properties": [83,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings/roles": [84,[12]],
		"/(not-authenticated)/(deprecated)/project/[project]/[phase=integer]/settings/users": [85,[12]],
		"/(authenticated)/project/[manual]/[project]": [42,[2]],
		"/(authenticated)/project/[manual]/[project]/[phase]": [43,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/audit": [44,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/audit/approved-documents": [45,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/audit/criterion/[criterion]": [46,[2,9]],
		"/(not-authenticated)/(deprecated)/project/[manual]/[project]/[phase]/audit/documentation": [73,[12]],
		"/(authenticated)/project/[manual]/[project]/[phase]/audit/subject/[subject]": [47,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/criterion/[criterion]": [48,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/overview": [49,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/overview/edit": [50,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings": [51,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/general": [52,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/notifications": [53,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/preferences": [54,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/profile": [55,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/properties": [56,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/roles": [57,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/settings/users": [58,[2,9,10]],
		"/(authenticated)/project/[manual]/[project]/[phase]/statistics": [59,[2,9,11]],
		"/(authenticated)/project/[manual]/[project]/[phase]/subject/[subject]": [60,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/tasks": [61,[2,9]],
		"/(authenticated)/project/[manual]/[project]/[phase]/uploaded-documents": [62,[2,9]],
		"/(not-authenticated)/redirect": [92,[12]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';